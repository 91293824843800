@import "../css/variables.scss";

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #2a1b7d;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #2a1b7d;
}

.scroll-box {
    overflow-y: scroll;
    height: 200px;
    border: 1px solid #ccc;
}

main {
    width: 100%;
}

.d-flex {
    display: flex;
}

.logo-section {
    display: flex;
    justify-content: center;
}

.logo {
    width: 90%
}

.white-noise-img {
    border-radius: 10px;
    width: 50px;
    background-color: #2a1b7d;
    padding: 3px;

}

.white-noise-img-mix {
    border-radius: 10px;
    width: 50px;
    padding: 3px;
    margin-right: 5px;

}



.img {
    border-radius: 10px;
    width: 60px
}

// .sleepia-logo-header {
//     width: 120%
// }

.bg-purple {
    background-color: $base-color;
}

.hidden {
    display: block;
}

.show {
    display: none;
}

@media (min-width: 599px) {
    .hidden {
        display: none;
    }

    .show {
        display: block;
    }
}

a {
    font-weight: normal;
    z-index: 999;
    word-break: break-all;
}

.badge {
    background-color: #A83811;
    color: #fff;
    padding: 0.5px 3px;
    border-radius: 5px;
    margin-left: 4px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}